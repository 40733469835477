/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import set from 'lodash/set';
import get from 'lodash/get';
import Button from '../../globals/buttons/Button';
import SelectComplex from './SelectComplex';
import CheckboxGroup from './CheckboxGroup';
import { getValidationDatabyType } from '../DynamicForm/helper/filter';
import '../forms.scss';

/**
 * @description - SkillsPicker component.
 * @param {Object} props - Input Props.
 * @returns {Node} - HTML Template.
 */
const SkillsPicker = (props) => {
  const {
    value,
    setValue,
    subType,
    name,
    getValues,
    requiredValidation,
    t,
    cssClass,
    titleText,
    id,
    label,
    dataValidations,
    validateOnLoad,
    handleChange,
    formState,
    contentLoading,
    customError,
    register,
  } = props;

  const [skillSet, setSkillSet] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const fieldError = get(customError, `${name}_hidden`);
  const requiredData =
    requiredValidation.length > 0
      ? requiredValidation[0]
      : getValidationDatabyType(dataValidations, 'required');
  const [pickerValue, setPickerValue] = useState('');
  let param = validateOnLoad ? { shouldValidate: true } : {};
  let param2 = { shouldValidate: true, shouldDirty: true };

  useEffect(() => {
    if (value?.length > 0) {
      setSelectedSkills(value);
      setSkillSet(value);
      setValue(`${name}_hidden`, value, { ...param, shouldDirty: true });
    } else {
      setSelectedSkills([]);
      setSkillSet([]);
      setValue(`${name}_hidden`, null, param);
    }
  }, [value]);

  const onSubmit = () => {
    let pickerFieldVal =
      subType === 'Text' ? pickerValue : getValues(`${name}_picker`);
    let newSelectedSkill = null;
    if (pickerFieldVal) {
      let formValue = getValues(name) || [];
      let selectedItem =
        Object.keys(pickerFieldVal).length > 0 &&
        Object.keys(pickerFieldVal).includes('label')
          ? pickerFieldVal.label
          : pickerFieldVal;
      selectedItem = selectedItem.split().filter((item) => item !== '');
      newSelectedSkill = [...new Set([...formValue, ...selectedItem])];
      setSelectedSkills(newSelectedSkill);
      setSkillSet([...new Set([...skillSet, ...selectedItem])]);
    }
    setValue(`${name}_hidden`, newSelectedSkill, param2);
    set(formState.touched, name, true);
    setValue(`${name}_picker`, null);
    setPickerValue('');
  };

  const updateDefaultOptions = (values) => {
    setSelectedSkills(values);
    setValue(`${name}_hidden`, values, param);
    handleChange();
  };

  const getBlockComponent = () => {
    switch (subType) {
      case 'Text':
        return (
          <div className="form-block complex">
            <input
              name={name}
              className={pickerValue ? 'filled' : ''}
              id={id}
              value={pickerValue}
              type="text"
              autoComplete="off"
              onChange={(e) => {
                setPickerValue(e?.target?.value);
              }}
            />
            <label htmlFor={id}>
              <div className="label-text">{label}</div>
            </label>
          </div>
        );
      case 'TypeaheadDropdown':
        return (
          <SelectComplex
            {...props}
            value=""
            name={`${name}_picker`}
            requiredValidation={[]}
            dataValidations={[]}
            cssClass={selectedSkills.length === 0 && cssClass}
            handleChange={onSubmit}
            contentLoading={contentLoading}
          />
        );
    }
  };

  return (
    <section className="picker-template">
      <div className="personal-info-form">
        <div className="appflow profile-skills">
          <div
            className={`add-skills complex ${fieldError ? 'error withoutBg' : ''}`}
          >
            <div className="dynamic-form">
              {selectedSkills && getBlockComponent()}
              {fieldError && requiredData?.fields?.value?.value && (
                <div className="error-msg" id={`err_${id}`} role="alert">
                  {fieldError.type === 'required'
                    ? requiredData?.fields?.message?.value
                      ? requiredData?.fields?.message?.value.replace(
                          '{0}',
                          label?.replace('*', '')
                        )
                      : t('mandatory-field-message')
                    : fieldError?.message.replace('{0}', label?.replace('*', ''))}
                </div>
              )}
            </div>
            {subType === 'Text' && (
              <div className="add-btn">
                <Button
                  cssClass="blue"
                  text={t('add-skill')}
                  handleButtonClick={onSubmit}
                />
              </div>
            )}
          </div>
          {skillSet && skillSet.length > 0 && (
            <>
              {titleText && <div className="suggestedSkills">{titleText}</div>}
              <div className="skills-list">
                <CheckboxGroup
                  {...props}
                  name={name}
                  id={`${id}_checkbox`}
                  options={skillSet}
                  defaultOptions={selectedSkills}
                  type="checkbox"
                  key={`${id}_checkbox`}
                  label=""
                  updateDefaultOptions={updateDefaultOptions}
                  isPicker
                  requiredValidation={[]}
                />
              </div>
            </>
          )}
          <input
            type="hidden"
            name={`${name}_hidden`}
            defaultValue={selectedSkills}
            ref={
              register
                ? register({
                    required: requiredData?.fields?.value?.value || false,
                  })
                : () => {}
            }
          />
        </div>
      </div>
    </section>
  );
};

SkillsPicker.defaultProps = {
  updateSelectedSkills: () => {},
  onSubmit: () => {},
  handleChange: () => {},
  items: [],
  titleText: '',
  cssClass: '',
  optionData: {},
};

SkillsPicker.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  titleText: PropTypes.string.isRequired,
  cssClass: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default SkillsPicker;
