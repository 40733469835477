import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  checkDataExists,
  getImg,
  isNorthAmericanCountry,
} from '../../../utils/helperUtils';
import {
  Text,
  withPlaceholder,
  withSitecoreContext,
  mediaApi,
} from '@sitecore-jss/sitecore-jss-react';
import ButtonLink from '../globals/link/ButtonLink';
import './page-banners.scss';
import { useSelector } from 'react-redux';

/**
 * @description - Banner Insights.
 * @param {Object} props - Input props.
 * @returns {Node} - HTML Template.
 */
const NarrowPageBanner = (props) => {
  const { fields, BreadCrumb, sitecoreContext } = props;
  const {
    title,
    text,
    ctaText,
    ctaUrl,
    componentBackgroundColor,
    backgroundimage,
    icon,
  } = fields;
  const brandName = sitecoreContext?.Country?.brandName?.toLowerCase() || 'manpower';
  const countryName = sitecoreContext?.Country?.name?.toLowerCase() || '';
  const loginReducer = useSelector((state) => state.loginReducer);
  let color_code = '';
  const updatebackgroundColor =
    componentBackgroundColor &&
    componentBackgroundColor?.map((item) => {
      const { colorCode } = item?.fields;
      color_code = checkDataExists(colorCode) ? checkDataExists(colorCode) : '';
      color_code = color_code.split(',');
      if (color_code.length > 1) {
        color_code = `linear-gradient(52deg, ${color_code[0]} 34%, ${color_code[1]})`;
      } else {
        color_code = ` ${color_code}`;
      }
      return color_code;
    });
  const styleObj = {
    backgroundImage: backgroundimage
      ? `url(${mediaApi.updateImageUrl(
          getImg(backgroundimage)
        )}) , url(${mediaApi.updateImageUrl(getImg(icon))})`
      : null,
  };

  const getButtonLink = () => {
    // Check if data.ctaUrl.value.href contains the target domain and replacing it with current user email -- only applicable for one section 'Explore Job Fit' in US
    const ctaUrlData = { ...ctaUrl };
    const targetDomain = 'https://insights.manpowergroupassessments.com';
    const isUSAndIncludesTargetDomain =
      isNorthAmericanCountry(countryName) &&
      ctaUrlData?.value?.href.includes(targetDomain);
    const loggedInUserEmail = loginReducer?.user?.email;
    const updatedHref = ctaUrlData?.value?.href.replace(
      /Email=[^&]+/,
      `Email=${loggedInUserEmail}`
    );
    const updatedCtaUrlData = {
      ...ctaUrlData,
      value: {
        ...ctaUrlData.value,
        href: updatedHref,
        url: updatedHref,
      },
    };
    return (
      <ButtonLink
        cssClass="reversed"
        ctaText={ctaText}
        ctaUrl={isUSAndIncludesTargetDomain ? updatedCtaUrlData : ctaUrl}
      />
    );
  };

  return (
    <section
      className={`banner-section insights with-logo narrow ${brandName}`}
      style={{ background: `${updatebackgroundColor}` }}
    >
      <div className={`banner-section__overlay`} style={{ ...styleObj }}></div>
      <div
        className={`banner-section__overlay mobile`}
        style={{
          backgroundImage: backgroundimage
            ? `url(${mediaApi.updateImageUrl(getImg(icon))})`
            : null,
        }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ">
            {BreadCrumb?.map((component, index) => {
              if (component.props && component.props.type === 'text/sitecore')
                return component;
              return (
                <Fragment key={index}>
                  <div className={`themeColor ${brandName}`}>{component}</div>
                </Fragment>
              );
            })}
            {title && (
              <h1 className="title">
                <Text field={title} />
              </h1>
            )}
            {title && (
              <p className="large reversed" id="insights-page-description">
                <Text field={text} />
              </p>
            )}
            {getButtonLink()}
          </div>
        </div>
      </div>
    </section>
  );
};

NarrowPageBanner.defaultProps = {
  fields: {
    title: { value: '' },
    text: { value: '' },
    ctaText: '',
    ctaUrl: '',
    backgroundImage: '',
    icon: '',
    backgroundimage: '',
    componentBackgroundColor: [],
  },
};

NarrowPageBanner.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.shape({ value: PropTypes.string.isRequired }),
    text: PropTypes.shape({ value: PropTypes.string.isRequired }),
    ctaText: {
      value: PropTypes.string,
    },
    ctaUrl: {
      value: PropTypes.shape({}),
    },

    backgroundimage: {
      value: PropTypes.string,
    },
    icon: {
      value: PropTypes.string,
    },
    componentBackgroundColor: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

const narrowContainer = withSitecoreContext()(
  withPlaceholder([{ placeholder: 'jss-breadcrumb', prop: 'BreadCrumb' }])(
    NarrowPageBanner
  )
);

export default narrowContainer;
