import React, { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import set from 'lodash/set';
import './jobpriorities.scss';
import { useSelector } from 'react-redux';
import dragIcon from '../../../../../assets/images/dragIcon.svg';

const JobPriorities = (props) => {
  const { formProps, form, options, register } = props;
  const { setValue, name, validateOnLoad, formState, t } = formProps;
  const [items, setItems] = React.useState([]);

  const profileInfo = useSelector((state) => state.profileInfoReducer?.data);

  const profileInfoJobPriorityValue =
    profileInfo.PersonalDetails.data[0].jobPrioritiesList;

  useEffect(() => {
    if (profileInfo.Id && profileInfoJobPriorityValue.length > 0) {
      const priorityValues = profileInfoJobPriorityValue.map((item, index) => ({
        ...item,
        label: item.content,
        id: String(index + 1),
      }));
      setItems(priorityValues);
    } else {
      const modifiedData = options.map((item, index) => ({
        ...item,
        order: String(index + 1),
        id: String(index + 1),
      }));
      setItems(modifiedData);
    }
  }, [profileInfo]);

  const convertToApiFormat = (data) => {
    const result = {};
    data.forEach((item) => {
      result[item.name] = item.order;
    });
    return JSON.stringify(result);
  };
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updatedItems = [...items];
    const movedItem = updatedItems.find((item) => item.id === result.draggableId);

    // Remove the moved item from the list
    updatedItems.splice(result.source.index, 1);
    // Insert the moved item at its new position
    updatedItems.splice(result.destination.index, 0, movedItem);
    // Reassign order values to all items based on their new positions
    updatedItems.forEach((item, index) => {
      item.order = index + 1;
    });
    setItems(updatedItems);
    const param = validateOnLoad ? { shouldValidate: true } : {};
    setValue(name, convertToApiFormat(updatedItems), param);
    set(formState.touched, name, true);
  };

  return (
    <>
      <table className="job-priority">
        <thead>
          <tr>
            <th></th>
            <th>{t('rank')} </th>
            <th>{t('statement')}</th>
          </tr>
        </thead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <tbody
                className="job-item-row"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <tr
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className="job-item-row"
                      >
                        <td>
                          <img src={dragIcon} alt="drag-icon" />
                        </td>
                        <td>{item.order}</td>
                        <td>{item.label}</td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
      <input
        type="hidden"
        name={`${form?.fields?.name?.value}`}
        ref={register ? register : () => {}}
      />
    </>
  );
};

export default JobPriorities;
